import React from 'react';
import TimesXWordThrowback from './assets/Screenshots/TimesXWordThrowback.png';

function Projects (props) {
  return (
    <div className='mainView'>
      <div className='highlightedProjects'>
        <h1 className='projectPageHeader'>Highlighted Projects</h1>

        <div className='projectContainer'>
          <div className='imageContainer'>
            <img src={TimesXWordThrowback} className='projectImage'/>
          </div>
          <div className='projectDetails'>
            <h3 className='projectTitle'>TimesXWordThrowback</h3>
            <h4 className='description'>FB-integrated crossword game using past clues from NY Times crossword puzzles.</h4>
            <div className='technologies'>
              <div className='React'>React</div>
              <div className='API'>API</div>
              <div className='CSS'>CSS</div>
              <div className='Express'>Express</div>
            </div>
            <b><a href='https://timesxwordthrowback.surge.sh'>Deployed Link</a></b>
            <b><a href='https://github.com/aefritz/crossword-game'>Github</a></b>
          </div>
        </div>

        <div className='projectContainer'>
          <div className='imageContainer'>
            <img src={props.projects[1].image} className='projectImage'/>
          </div>
          <div className='projectDetails'>
            <h3 className='projectTitle'>{props.projects[1].name}</h3>
            <h4 className='description'>{props.projects[1].description}</h4>
            <div className='technologies'>
              {props.projects[1].technologies.map(technology => <div className={technology}>{technology}</div>)}
            </div>
            <b><a href={props.projects[1].deployed_link}>Deployed Link</a></b>
            <b><a href={props.projects[1].link}>Github</a></b>
          </div>
        </div>

        <div className='projectContainer'>
          <div className='imageContainer'>
            <img src={props.projects[3].image} className='projectImage'/>
          </div>
          <div className='projectDetails'>
            <h3 className='projectTitle'>{props.projects[3].name}</h3>
            <h4 className='description'>{props.projects[3].description}</h4>
            <div className='technologies'>
              {props.projects[3].technologies.map(technology => <div className={technology}>{technology}</div>)}
            </div>
            <b><a href={props.projects[3].deployed_link}>Deployed Link</a></b>
            <b><a href={props.projects[3].link}>Github</a></b>
          </div>
        </div>


        <div className='projectContainer'>
          <div className='imageContainer'>
            <img src={props.projects[4].image} className='projectImage'/>
          </div>
          <div className='projectDetails'>
            <h3 className='projectTitle'>{props.projects[4].name}</h3>
            <h4 className='description'>{props.projects[4].description}</h4>
            <div className='technologies'>
              {props.projects[4].technologies.map(technology => <div className={technology}>{technology}</div>)}
            </div>
            <b><a href={props.projects[4].deployed_link}>Deployed Link</a></b>
            <b><a href={props.projects[4].link}>Github</a></b>
          </div>
        </div>

        <h1 className='projectPageHeader'>Other Projects</h1>

        {props.projects.filter(project => ((props.projects.indexOf(project) !== 1) || (props.projects.indexOf(project) !== 3) || (props.projects.indexOf(project) !== 4))).map(project =>
          <div className='projectContainer'>
            <div className='imageContainer'>
              <img src={project.image} className='projectImage'/>
            </div>
            <div className='projectDetails'>
              <h3 className='projectTitle'>{project.name}</h3>
              <h4 className='description'>{project.description}</h4>
              <div className='technologies'>
                {project.technologies.map(technology => <div className={technology}>{technology}</div>)}
              </div>
              <b><a href={project.deployed_link}>Deployed Link</a></b>
              <b><a href={project.link}>Github</a></b>
            </div>
          </div>
        )}

      </div>


    </div>
  )
}

export default Projects;
