import React, { Component } from 'react';
import axios from 'axios';
import './App.css';
import icon from './assets/icon.png';
import headshot from './assets/headshot.png';
import github from './assets/github.png';
import resume from './assets/Resume.pdf';
import fish from './assets/fish.png';
import {Link, Route} from 'react-router-dom';
import {Document, Page} from 'react-pdf';
import ResponsiveEmbed from 'react-responsive-embed';
import ColorSwap from './assets/Screenshots/ColorSwap.png';
import CubePermutations from './assets/Screenshots/CubePermutations.png';
import MoonPhases from './assets/Screenshots/MoonPhases.png';
import PostPic from './assets/Screenshots/PostPic.png';
import RiddlesDiary from './assets/Screenshots/RiddlesDiary.png';
import Router from './assets/Screenshots/Router.png';
import SlidingPuzzle from './assets/Screenshots/SlidingPuzzle.png';
import WavyBackground from './assets/Screenshots/WavyBackground.png';
import WikiView from './assets/Screenshots/WikiView.png';
import Projects from './Projects';

class App extends Component {
  constructor() {
    super();
    this.state = {
      fishes: [1,2,3,4,5,6,7,8,9],
      projects: [
        {
          name: 'Wavy Background',
          link: 'https://github.com/aefritz/WavyBackground',
          image: WavyBackground,
          technologies: ['CSS','Javascript','HTML'],
          description: "Animation using sinusoidal functions and flexbox.",
          deployed_link: 'https://few-stomach.surge.sh'
        },{
          name: 'Post Pic',
          link: 'https://github.com/aefritz/ImageSharingCRUD',
          image: PostPic,
          technologies: ['React','API','Express'],
          description: "Instagram-like app relying on Cloudinary API for handling cloud storage.",
          deployed_link: 'http://luxuriant-bun.surge.sh/'
        },{
          name: 'Moon Phases',
          link: 'https://github.com/aefritz/MoonPhases',
          image: MoonPhases,
          technologies: ['CSS','Javascript','HTML'],
          description: "Simulation of the phases of the moon over the course of the lunar month using Javascript and CSS animations.",
          deployed_link: ""
        },{
          name: 'Wiki View',
          link: 'https://github.com/aefritz/WikiView',
          image: WikiView,
          technologies: ['React', 'CSS', 'API'],
          description: "Visualizes connectedness of human knowledge by rendering word cloud of hyperlinks. Powered by the Wikipedia API.",
          deployed_link: 'https://strong-pear.surge.sh/'
        },{
          name: 'Sliding Puzzle',
          link: 'https://github.com/aefritz/Sliding-Tile-Game',
          image: SlidingPuzzle,
          technologies: ['Javascript', 'HTML', 'CSS'],
          description: "Variation of the classic 15-Puzzle. Users can upload their own puzzle image and adjust the grid size for a challenge.",
          deployed_link: 'https://decorous-flag.surge.sh'
        },{
          name: 'Router',
          link: 'https://github.com/aefritz/Router',
          image: Router,
          technologies: ['Javascript', 'SVG', 'HTML', 'CSS'],
          description: "Finds the shortest path (often a polygon) between pairs of (x,y) points.",
          deployed_link: 'https://curious-camp.surge.sh/'
        },{
          name: 'Riddles Diary',
          link: 'https://github.com/aefritz/RiddlesDiary',
          image: RiddlesDiary,
          technologies: ['Javascript', 'SVG', 'HTML', 'CSS'],
          description: "Allows user to create disappearing drawings.",
          deployed_link: 'https://defiant-beast.surge.sh/'
        },{
          name: 'Color Swap',
          link: 'https://codepen.io/austin-fritz/pen/moqKxr?editors=0001',
          image: ColorSwap,
          technologies: ['JQuery', 'HTML', 'CSS'],
          description: "JQuery experiment -- change the colors of the page dynamically and add your own colors!",
          deployed_link: ""
        },{
          name: 'Cube Permutes',
          link: 'https://github.com/aefritz/CubePermutations',
          image: CubePermutations,
          technologies: ['Javascript', 'SVG', 'HTML', 'CSS'],
          description: "Iterates through all the permuations of a cube's edges switched on or off -- inspired by the work of artist Sol Lewitt",
          deployed_link: 'https://cautious-veil.surge.sh/'
        }
      ],
      selectedProjects: [],
      navClassName: 'displayOff',
      mainClassName: 'view1',
      styles: {
        style0: {
          top: `${50+90*Math.random()}px`,
          left: `-25%`,
        },
        style1: {
          top: `${50+90*Math.random()}px`,
          left: `1.0%`,
        },
        style2: {
          top: `${50+90*Math.random()}px`,
          left: `26.5%`,
        },
        style3: {
          top: `${50+90*Math.random()}px`,
          left: `52.5%`
        },
        style4: {
          top: `${50+90*Math.random()}px`,
          left: `80%`,
        },
        style5: {
          top: `${50+90*Math.random()}px`,
          left: `107.5%`,
        },
        style6: {
          top: `${50+90*Math.random()}px`,
          left: `135%`,
        },
        style7: {
          top: `${50+90*Math.random()}px`,
          left: `162.5%`,
        },
        style8: {
          top: `${50+90*Math.random()}px`,
          left: `190%`,
        },
        style9: {
          top: `0px`,
          left: `-25%`,
        },
        style10: {
          top: `250px`,
          left: `1.0%`,
        },
        style11: {
          top: `0px`,
          left: `26.5%`,
        },
        style12: {
          top: `250px`,
          left: `52.5%`
        },
        style13: {
          top: `0px`,
          left: `80%`,
        },
        style14: {
          top: `250px`,
          left: `107.5%`,
        },
        style15: {
          top: `0px`,
          left: `135%`,
        },
        style16: {
          top: `250px`,
          left: `162.5%`,
        },
        style17: {
          top: `0px`,
          left: `190%`,
        },
      }
    }
    this.handleIconClick = this.handleIconClick.bind(this);
  }



  async componentDidMount() {
    return setInterval(function () {
      let fishes = document.querySelectorAll('.fish');
      let elements = document.querySelectorAll('.reelElement');
      elements.forEach(function (element) {
        if ((parseFloat(element.style.left) >= 210) && (parseFloat(element.style.left) < 245)) {
          element.style.transitionProperty = 'none';
        } else {
          element.style.transitionProperty = 'initial';
        }
        element.style.left = `${(((parseFloat(element.style.left))+27.5)%245)-25}%`;
      });
      fishes.forEach(function(fish) {
        if ((parseFloat(fish.style.left) >= 210) && (parseFloat(fish.style.left) < 245)) {
          fish.style.transitionProperty = 'none';
        } else {
          fish.style.transitionProperty = 'initial';
        }
        fish.style.left = `${(((parseFloat(fish.style.left))+27.5)%245)-25}%`;
        });
      }
  , 500);
  }



  handleIconClick () {
    if (this.state.navClassName === 'displayOff') {
      this.setState({
        navClassName: 'displayOn',
      })
    } else {
      this.setState({
        navClassName: 'displayOff',
      })
    }
  }

  render() {
    return (
      <div className="App">

        <section className='social-links'>

          <div>

            <img className='nav-icon' src={icon} onClick={this.handleIconClick}></img>

          </div>

          <div>

            <a href = "https://www.instagram.com/austin_fritz/">
            <img src = "https://i.imgur.com/6cmpbck.png" className = "logo"/></a>

            <a href = "https://www.linkedin.com/in/austin-fritz-665327a9/">
            <img src = "https://i.imgur.com/wo1WwUt.png" className = "logo"/></a>

            <a href= "https://www.facebook.com/austin.fritz.394">
            <img src = "https://i.imgur.com/rqDucJf.png" className = "logo"/></a>

            <a href= "https://github.com/aefritz">
            <img src = {github} className = "logo"/></a>

          </div>

        </section>

        <main>

          <nav className={this.state.navClassName}>

            <div className='headshot-container'>
              <img className='headshot' src={headshot}/>
            </div>

            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/about'>About</Link></li>
              <li><Link to='/resume'>Resume</Link></li>
              <li><Link to='/projects'>Projects</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
            </ul>

          </nav>

          <Route exact path = '/' render={(props) => {

          return (
          <div className='mainView'>
            <h1 className='name-header'>austin fritz.</h1><h1 className='title-header'>fullstack web developer.</h1>

            <h2>explore my stream of consciousness</h2>
            <h4><Link to='/projects'>click here for more project details.</Link></h4>
            <div className='reel'>
              {
                this.state.projects.map(fishdiv => (
                  <div className='fish' style={this.state.styles['style' + (this.state.projects.indexOf(fishdiv)+7)]}>
                    <img src={fish}/>
                  </div>
                ))
              }
              {
                this.state.projects.map(project => (
                  <div className='reelElement' style={this.state.styles['style' + this.state.projects.indexOf(project)]}>
                  <span>{project.name}</span><br/>
                  <a href={project.link}><img className='reelImage' src={project.image}/></a>
                  <div className='tech-box'>
                    {project.technologies.map(technology => (<p className={technology}>{technology}</p>))}
                  </div>
                  </div>
                )
              )
              }
          </div>
          </div>)}}/>
          <Route path = '/about' render={(props)=>(
            <div className = 'mainView'>
              <h2 className='about-text'>As a math-teacher-turned-developer, I thrive on open-mindedness and curiosity when collaborating to solve technical challenges. I consider the intersections between functionality, mathematical structure, and aesthetics in my approach to problems as a full-stack developer.</h2>
            </div>
          )}/>
          <Route path = '/contact' render={(props)=>(
            <div className = 'mainView'>
            <div className = 'contactlink'>
              <h1><a href='mailto:austin.fritz33@gmail.com'>- <span>austin.fritz33@gmail.com</span></a></h1>
              <h1><a href='https://www.linkedin.com/in/austin-fritz-665327a9/'>- <span>LinkedIn</span></a></h1>
            </div>
            </div>
          )}/>
          <Route path = '/resume' render={(props)=>(
            <div className = 'mainView'>
              <iframe src="https://drive.google.com/file/d/1LcpPW_BMEUYXJC0I8o2tjlgZA_H3RXx9/preview?usp=embed_googleplus" width="100%" height="600px" />
            </div>
          )}/>
          <Route path = '/projects' render={(props)=>(
            <Projects projects={this.state.projects}/>
          )}/>
        </main>

        <footer>
          <p>2019. Austin Fritz. Designed with care & React.js in Brooklyn, NY.</p>
        </footer>

      </div>
    );
  }
}

export default App;
